<template>
  <div class="body">
    <div
      class="card_wrapper"
      :style="{
        padding: ispadding == true ? '0 20px' : '0',
      }"
    >
      <div class="card_item_pic">
        <img :src="imgSrc()" alt="" class="card_item_pic_img" />
      </div>
    </div>
  </div>
</template>

<script>
// import menuListObj from "@/views/systemSettings/js/menuList.js";
// let jumpRouterObjPath = menuListObj.jumpPath;
// import $i18n from "@/utils/i18n"; //多语言
import { geturl } from "./api.js"; //获取域名
export default {
  data() {
    return {
      // cardList: [
      //   {
      //     title: $i18n.t("label.mobile.side.tab.app"), //应用程序
      //     desc: $i18n.t("vue_label_systemSettings_setorganization"), //设置组织应用程序，为各职能人群设置不同应用程序菜单
      //     btnList: [$i18n.t("vue_label_systemSettings_startSet")], //开始设置
      //     jumpRouterObjPath: `${
      //       this.lightningDomainStr
      //     }/uiSetup.action?binding=${this.$CCDK.CCToken.getToken()}`, //跳转路由
      //     isOpenOldSystem: true, //打开老系统
      //   },
      //   {
      //     title: $i18n.t("label.permission.detail.app.object"), //对象设置
      //     desc: $i18n.t("vue_label_systemSettings_set_business"), //设置业务对象布局和字段，使之更符合您的业务
      //     btnList: [$i18n.t("vue_label_systemSettings_startSet")], //开始设置
      //     jumpRouterObjPath: `${
      //       this.lightningDomainStr
      //     }/uiSetup.action?binding=${this.$CCDK.CCToken.getToken()}`, //跳转路由
      //     isOpenOldSystem: true, //打开老系统
      //   },
      //   {
      //     title: $i18n.t("vue_label_systemSettings_mobel_set"), //移动端设置
      //     desc: $i18n.t("vue_label_systemSettings_set_android"), //设置组织安卓和IOS软件的菜单和功能，使其符合企业用户的使用方式
      //     btnList: [$i18n.t("vue_label_systemSettings_startSet")], //开始设置
      //     jumpRouterObjPath: `${
      //       this.lightningDomainStr
      //     }/uiSetup.action?binding=${this.$CCDK.CCToken.getToken()}`, //跳转路由
      //     isOpenOldSystem: true, //打开老系统
      //   },
      //   {
      //     title: this.$i18n.t("label.Path.Setting"), //进程设置
      //     desc: this.$i18n.t("vue_label_systemSettings_set_course"), //设置进程，设置每个环节的要点贴士，引导大家按要求完成进程
      //     btnList: [this.$i18n.t("vue_label_systemSettings_startSet")], //开始设置
      //     jumpRouterObjPath: "setuppage/processSettings",
      //   },
      //   {
      //     title: this.$i18n.t("vue_label_systemSettings_list"), //相关列表展示设置
      //     desc: this.$i18n.t("vue_label_systemSettings_set_record"), //设置记录详情相关列表的展示形式，可以切换卡片形式和列表式两种
      //     btnList: [this.$i18n.t("vue_label_systemSettings_startSet")], //开始设置
      //     jumpRouterObjPath: "setuppage/userPageSetup",
      //   },
      //   {
      //     title: this.$i18n.t("vue_label_systemSettings_downloadapp"), //下载移动程序
      //     desc: this.$i18n.t("label.download.CloudCCAPP"),
      //     btnList: [this.$i18n.t("label.downloadapp")],
      //     isShowQr: true,
      //   },
      // ],
      cardQr: require("./images/qr.png"),
      isShowQr: false,
      ispadding: true,
      domainStr: "", //环境域名
    };
  },
  created() {
    this.geturlMethod();
  },
  methods: {
    // 获取域名
    async geturlMethod() {
      let op = {};
      let res = await geturl(op);
      this.domainStr = res.data;
    },
    imgSrc() {
      //这些svg替不了
      // return require(`./images/top_img${index}.svg`);
      return require(`./images/Slice 9.png`);
    },
    cardBtnClickEvent(e) {
      this.lightningDomainStr = this.domainStr;
      let { jumpRouterObjPath, isOpenOldSystem } = e;
      if (isOpenOldSystem) {
        e.jumpRouterObjPath = `${
          this.lightningDomainStr
        }/uiSetup.action?binding=${this.$CCDK.CCToken.getToken()}`;
        window.open(e.jumpRouterObjPath);
      }else {
        this.$router.push(`/systemSettings/${jumpRouterObjPath}`);
      }
    },
    cardBtnMouseenterEvent() {
      this.isShowQr = true;
    },
    cardBtnMouseleaveEvent() {
      this.isShowQr = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  height: 100%;
  .card_wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    // .card_item {
    //   // width: 32.5%;
    //   width: 100%;
    //   margin-top: 16px;
    //   background-color: #fff;
    //   border-radius: 5px;
    //   border: 1px solid #dedcda;
    //   position: relative;
    //   .card_item_top {
    //     display: flex;
    //     background-color: #ccc;
    //     .card_item_top_img {
    //       width: 100%;
    //     }
    //   }
    //   // 底端部分
    //   .card_item_bottom {
    //     display: flex;
    //     align-items: center;
    //     flex-direction: column;
    //     padding: 0 30px;
    //     .card_item_bottom_title {
    //       margin: 10px 0;
    //     }
    //     .card_item_bottom_option {
    //       margin-top: 20px;
    //       display: flex;
    //       justify-content: center;
    //       height: 50px;
    //       width: 100%;
    //       .btn {
    //         height: 32px;
    //         // width: 84px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         position: absolute;
    //         bottom: 20px;
    //         left: 50%;
    //         transform: translateX(-50%);
    //       }
    //     }
    //   }
    //   // 二维码
    //   .card_qr_wrapper {
    //     position: absolute;
    //     top: 45%;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     text-align: center;
    //     img {
    //       width: 50%;
    //       // height: 50%;
    //     }
    //   }
    // }
    .card_item_pic{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .card_item_pic_img{
        width: 80%;
      }
    }
  }
}
</style>